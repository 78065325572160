import React, { FunctionComponent, useEffect, useState } from "react";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Button, ButtonGroup } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type TPaginationProps = {
  currentPageIdx: number,
  itemsCount: number,
  itemsPerPage: number,
  setItemsPerPage: (perPage: number) => void,
  setCurrentPageIdx: (idx: number) => void
};

export const Pagination: FunctionComponent<TPaginationProps> = ({
  currentPageIdx,
  itemsCount,
  itemsPerPage,
  setItemsPerPage,
  setCurrentPageIdx
}) => {
  const [pages, setPages] = useState(1);

  useEffect(() => {
    if (itemsCount > 0) {
      setPages(Math.ceil(itemsCount / itemsPerPage));
      setCurrentPageIdx(0);
    }
  }, [itemsCount, itemsPerPage]);

  const goPrev = () => setCurrentPageIdx(currentPageIdx - 1);
  const canPrev = currentPageIdx > 0;

  const goNext = () => setCurrentPageIdx(currentPageIdx + 1);
  const canNext = currentPageIdx < pages - 1;

  return (
    <div className="box box-h box-gap box-middle">
      <Button outline disabled={!canPrev} onClick={() => goPrev()}>
        <FontAwesomeIcon size="lg" icon={faAngleLeft} />
      </Button>
      <div className="text-center" style={{ minWidth: "50px" }}>
        {currentPageIdx + 1} / {pages}
      </div>
      <Button outline disabled={!canNext} onClick={() => goNext()}>
        <FontAwesomeIcon size="lg" icon={faAngleRight} />
      </Button>
      <div className="text-nowrap">Rekordów: {itemsCount}</div>
      <div className="box box-h box-gap box-middle mob-hidden">
        <div className="text-nowrap">Na stronę:</div>
        <ButtonGroup>
          <Button
            color="light"
            active={itemsPerPage === 10}
            size="sm"
            onClick={() => setItemsPerPage(10)}
          >
            10
          </Button>
          <Button
            color="light"
            active={itemsPerPage === 20}
            size="sm"
            onClick={() => setItemsPerPage(20)}
          >
            20
          </Button>
          <Button
            color="light"
            active={itemsPerPage === 50}
            size="sm"
            onClick={() => setItemsPerPage(50)}
          >
            50
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};
