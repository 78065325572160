import React, { useState, FunctionComponent, useEffect } from "react";
import { Button, CustomInput, Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { Head } from "./Head";
import { Pagination } from "./Pagination";
import Loader from "../../components/Loader";
import { NoData } from "../../components/NoData";
import { BatchOperations } from "./BatchOperations";
import { TFeed, TPagedFeed } from "../../hooks/useFeed";
import _ from "lodash";
import { UserPreferences } from "../../UserPreferences";

type TRowButton = {
  color: string;
  faIcon: IconProp;
  onClickFn: (e: any) => void;
  tooltip?: string;
  title?: string;
  outline?: boolean;
  disabledFn?: (e: any) => boolean;
};

export type TColumnType = "text" | "number" | "date" | "task-status";

export type TColumnConfig = {
  key?: string;
  title: string;
  type?: TColumnType;
  renderFn: (e: any) => any;
  options?: {
    maxWidth?: string;
    preformatted?: boolean;
    noFilter?: boolean;
  };
};

export type TBatchOperation<T> = {
  name: string;
  onSubmitFn: (e: T[]) => void;
};

type TDataTableProps<T> = {
  feed: TPagedFeed<T> | TFeed<T>,
  searchBy?: string;
  columnConfig: TColumnConfig[];
  rowKeyFn: (e: any) => any;
  topComponents?: React.ReactNode[];
  rowButtons?: TRowButton[];
  batchOperations?: {
    [key: string]: TBatchOperation<T>;
  };
  title?: string,
  options?: {
    selectableRows?: boolean;
    canFilter?: boolean;
  };
};

function useRowSelect(): [string[], (rowIds: string[], selected: boolean) => void] {
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);

  const selectRows = (rowIds: string[], selected: boolean) => {
    if (selected)
      setSelectedRowIds([...selectedRowIds, ...rowIds]);
    else
      setSelectedRowIds(selectedRowIds.filter(e => !rowIds.includes(e)));
  };

  return [selectedRowIds, selectRows];
}

const DataTable: FunctionComponent<TDataTableProps<any>> = ({
  feed,
  searchBy,
  columnConfig,
  rowKeyFn,
  topComponents = [],
  batchOperations = {},
  rowButtons = [],
  title,
  options = {},
}) => {
  const [selectedRowIds, selectRows] = useRowSelect();
  const [filters, setFilters] = useState({});
  const data = feed.response;

  if ("filter" in feed)
    useEffect(() => feed.filter(filters), [filters]);

  const isFiltered = (e: string) => Object.keys(filters).length > 0 && filters[e] != null;

  return (
    <div className="box box-v box-fill box-gap">
      <Head
        onReload={() => feed.reload()}
        canSearch={searchBy != null}
        onSearch={_.debounce(v => "search" in feed ? feed.search(v, searchBy) : null, 300)}
        topComponents={topComponents}
        columnConfig={columnConfig}
        onFilter={v => setFilters(v)}
        canFilter={(options.canFilter ?? true) && "filter" in feed}
        filters={filters}
        title={title}
      />

      <Loader pending={feed.pending}>
        <div className="box box-v box-fill">
          {data.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  {options?.selectableRows && (
                    <td>
                      <CustomInput
                        type="checkbox"
                        id="header-checkbox"
                        checked={selectedRowIds.length === data.length} // all checked
                        onChange={e => {
                          const rowIds = data.map(row => rowKeyFn(row));
                          selectRows(rowIds, e.target.checked);
                        }}
                      />
                    </td>
                  )}

                  {columnConfig.map(e => (
                    <td
                      key={e.key ?? e.title}
                      className="text-nowrap clickable"
                      onClick={() => {
                        if (isFiltered(e.key))
                          setFilters({
                            ...filters,
                            [e.key]: undefined
                          })
                      }}
                    >{filters[e.key] && <FontAwesomeIcon icon={faFilter} />} {e.title}</td>
                  ))}

                  {rowButtons.length > 0 && <td />}
                </tr>
              </thead>

              <tbody>
                {data.map((rowData) => React.cloneElement(
                  <tr key={rowKeyFn(rowData)}>
                    {options?.selectableRows && (
                      <td>
                        <CustomInput
                          checked={selectedRowIds.includes(rowKeyFn(rowData))}
                          type="checkbox"
                          id={`checkbox-${rowKeyFn(rowData)}`}
                          onChange={e => selectRows([rowKeyFn(rowData)], e.target.checked)}
                        />
                      </td>
                    )}

                    {columnConfig.map((e, i) => (
                      <td key={`${rowKeyFn(rowData)}-${i}`} style={{ maxWidth: e.options?.maxWidth ?? "none" }}>
                        {
                          e.options?.preformatted
                            ? <pre className="cell">{e.renderFn(rowData)}</pre>
                            : <span className="cell">{e.renderFn(rowData)}</span>
                        }
                      </td>
                    ))}

                    {rowButtons.length > 0 && (
                      <td key={`row-buttons-${rowKeyFn(rowData)}`}>
                        <div className="box box-h box-gap">
                          {rowButtons.map((e, i) => (
                            <Button
                              key={`row-btn-${rowKeyFn(rowData)}-${i}`}
                              color={e.color}
                              size="sm"
                              onClick={() => e.onClickFn(rowData)}
                              title={e.tooltip}
                              outline={e.outline ?? true}
                              disabled={typeof e.disabledFn === "function" && e.disabledFn(rowData)}
                              className="text-nowrap"
                            >
                              <FontAwesomeIcon icon={e.faIcon} /> {e.title && e.title}
                            </Button>
                          ))}
                        </div>
                      </td>
                    )}
                  </tr>,
                ),
                )}
              </tbody>
            </Table>
          ) : (
            <NoData />
          )}
        </div>
      </Loader>

      {"itemsCount" in feed && (
        <div className="box box-h box-justified box-wrap">
          <Pagination
            itemsCount={feed.itemsCount}
            itemsPerPage={UserPreferences.instance.data.datatable?.itemsPerPage ?? feed.itemsPerPage}
            currentPageIdx={feed.currentPageIdx}
            setItemsPerPage={v => {
              UserPreferences.instance.data.datatable = {
                ...(UserPreferences.instance.data.datatable ?? {}),
                itemsPerPage: v
              }
              UserPreferences.instance.save();
              feed.setItemsPerPage(v);
            }}
            setCurrentPageIdx={feed.setCurrentPageIdx} />

          {selectedRowIds.length > 0 && (
            <BatchOperations
              rows={data.filter(row => selectedRowIds.includes(rowKeyFn(row)))}
              batchOperations={batchOperations}
              onSubmitFn={() => {
                const rowIds = data.map(row => rowKeyFn(row)); // deselect all
                selectRows(rowIds, false);
              }}
            />
          )}
        </div>
      )}

    </div>
  );
};

export default DataTable;
