import { faFilter, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Searchbar } from "../../components/@UI/Searchbar";
import React, { FunctionComponent, useRef } from "react";
import { Button } from "reactstrap";
import { Filters } from "./Filters";
import { TColumnConfig } from "./DataTable";
import { Sticky } from "../@UI/Services";

export type TBatchOperation = {
  name: string;
  onSubmitFn: (e: string[]) => void;
};

type THeadProps = {
  columnConfig: TColumnConfig[];
  onSearch: (e: string) => void;
  canSearch: boolean;
  onReload: () => void;
  topComponents?: React.ReactNode[];
  canFilter?: boolean;
  onFilter: (v: any) => void;
  filters: {};
  title?: string
};

export const Head: FunctionComponent<THeadProps> = ({
  columnConfig,
  onSearch,
  canSearch,
  onReload,
  topComponents,
  canFilter,
  onFilter,
  filters,
  title
}) => {
  const filterRef = useRef(null);

  return (
    <div className="box box-h box-justified box-gap box-middle">
      {canFilter && <Button innerRef={filterRef} secondary="true" outline onClick={() => {
        Sticky.open(filterRef.current, <Filters columnConfig={columnConfig} onSetFn={e => {
          onFilter(e);
          Sticky.closeTop();
        }} filters={filters} />)
      }}>
        <FontAwesomeIcon icon={faFilter} />
      </Button>}
      {canSearch && <Searchbar onSearch={searchText => onSearch(searchText)} />}

      {title && <h5>{title}</h5>}
      <div className="box box-h box-gap self-end">
        {topComponents.length > 0 && topComponents.map(e => e)}
      </div>

      <Button color="outline-secondary" onClick={() => onReload()} title="Przeładuj listę">
        <FontAwesomeIcon icon={faSyncAlt} />
      </Button>
    </div>
  );
};
