import React, { FunctionComponent, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faBell, faPowerOff, faTimes, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { SessionContext } from "../context/SessionContext";
import { logoutUser } from "../actions/user";
import EventList from "./EventList";
import { Badge } from "reactstrap";
import { EMPTY_STRING } from "../Constants";
import { useFeed } from "../hooks/useFeed";
import { Modal, Sticky } from "./@UI/Services";
import { IEvent, IFeatureEventProperties } from "../@shared/types";
import { useCache } from "../hooks/useCache";

type TTopbarProps = {
  title: string;
  faIcon: IconProp;
  onSideToggle: () => void;
  sideIsOpen: boolean;
};

const Topbar: FunctionComponent<TTopbarProps> = ({
  title,
  faIcon,
  onSideToggle,
  sideIsOpen
}) => {
  const session = useContext(SessionContext);
  const eventsToggleRef = useRef(null);
  const cache = useCache();
  const unreadEvents = cache.unreadEvents.data;

  return (
    <>
      <div className="head box box-h box-middle shadow-sm">
        <div className="side-toggle box box-middle" onClick={() => onSideToggle()}>
          <FontAwesomeIcon size="lg" icon={sideIsOpen ? faTimes : faBars} />
        </div>

        <div className="head-inner box box-h box-gap box-middle box-fill">
          <h3 className="box box-h box-middle box-fill">
            <FontAwesomeIcon icon={faIcon} />
            <span className="ml-3 text-truncate">{title}</span>
          </h3>

          <div className="box box-h box-gap box-middle">
            <div className="box box-h box-gap-sm box-middle mob-hidden mr-4">
              <FontAwesomeIcon icon={faUserCircle} size="lg" />
              <div>{session.user?.email ?? EMPTY_STRING}</div>
            </div>
            <div
              ref={eventsToggleRef}
              className="notification-icon"
              title="Ostatnie powiadomienia"
              onClick={() => Sticky.open(eventsToggleRef.current, <EventList onUpdate={(ids) => {
                Sticky.closeTop();
              }
              } />)}
            >
              {unreadEvents.length > 0 && (
                <Badge color="danger" pill>
                  {unreadEvents.length > 99 ? "99+" : unreadEvents.length}
                </Badge>
              )}
              <FontAwesomeIcon size="lg" className="clickable" icon={faBell} />
            </div>

            <div
              className="clickable"
              title="Wyloguj"
              onClick={() => Modal.confirm("Wylogowanie", "Czy na pewno chcesz się wylogować?", async () => {
                await logoutUser();
                session.setUser(null);
              })}
            >
              <FontAwesomeIcon size="lg" icon={faPowerOff} />
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default Topbar;
