import React, { FunctionComponent, useState } from "react";
import { Button, CustomInput } from "reactstrap";
import { Searchbar } from "../Searchbar";

export type TSelectorCompProps = {
  items: any[];
  selected: any[];
  onSelect: (itemIds: any[]) => void;
  single: boolean;
  displayFn: (e: any) => string;
  keyFn: (e: any) => any;
};

const SelectorComponent: FunctionComponent<TSelectorCompProps> = ({
  items,
  selected,
  onSelect,
  single,
  displayFn,
  keyFn,
}) => {
  const [selectedItems, setSelectedItems] = useState<any[]>(selected);
  const [visibleItems, setVisibleItems] = useState<any[]>(items);

  const onSearch = (text: string) => {
    if (text)
      setVisibleItems(
        items.filter(e =>
          displayFn(e)
            .toLowerCase()
            .includes(text),
        ),
      );
    else setVisibleItems(items);
  };

  const onChecked = (item: any, v: boolean) => {
    if (v) {
      if (!single) setSelectedItems([...selectedItems, item]);
      else setSelectedItems([item]);
    } else setSelectedItems(selectedItems.filter(e => keyFn(e) !== keyFn(item)));
  };

  const isSelected = (e: any) => {
    return selectedItems.map(f => keyFn(f)).includes(keyFn(e));
  };

  return (
    <div className="selector-component box box-v box-gap box-fill">
      <Searchbar onSearch={text => onSearch(text)} />

      <div className="box box-v box-fill scroll">
        {visibleItems.map(e => (
          <label className="box box-h" key={keyFn(e)}>
            <CustomInput
              checked={isSelected(e)}
              type="checkbox"
              id={`checkbox-${keyFn(e)}`}
              onChange={f => onChecked(e, f.target.checked)}
            />
            <span className={isSelected(e) ? "font-weight-bold" : ""}>{displayFn(e)}</span>
          </label>
        ))}
        {visibleItems.length === 0 && "Nie znaleziono wyników."}
      </div>

      <div className="box box-h box-justified">
        <Button color="primary" outline onClick={() => setSelectedItems([])}>
          Czyść
        </Button>
        <Button color="primary" onClick={() => onSelect(selectedItems)}>
          Wybierz
        </Button>
      </div>
    </div>
  );
};

export default SelectorComponent;
