import {
  faCheckCircle,
  faInfoCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EMPTY_STRING } from "../Constants";
import withEntityEdit, { TEditEntityHOCProps } from "../hocs/withEntityEdit";
import { useCache } from "../hooks/useCache";
import { FeatureEventType, FeatureType, IEvent, IFeatureEventProperties } from "../@shared/types";
import React, { FunctionComponent, useCallback } from "react";
import { dateFormatter } from "../utils/DateFormatter";
import { NoData } from "./NoData";

const EventList: FunctionComponent<{ onUpdate: (eventIds: number[]) => void } & TEditEntityHOCProps> = ({
  updateEntities,
  onUpdate
}) => {
  const cache = useCache();
  const unreadEvents = cache.unreadEvents.data;
  const getTitle = useCallback((e: IEvent<IFeatureEventProperties>) => {
    if (
      e.type === FeatureEventType.Breakdown &&
      findTest(e)?.type === FeatureType.Availability
    )
      return "Wykryto awarię";

    if (
      e.type === FeatureEventType.Breakdown &&
      findTest(e)?.type === FeatureType.Ssl
    )
      return "Certyfikat SSL wygasł";

    if (e.type === FeatureEventType.Recovery) return "Strona znowu działa";

    return "Informacja";
  }, []);

  const getTypeCss = useCallback((type: number) => {
    switch (type) {
      case FeatureEventType.Breakdown:
        return "danger";

      case FeatureEventType.Recovery:
        return "success";

      default:
        return "secondary";
    }
  }, []);

  const getCss = useCallback((e: IEvent) => {
    return `${!e._read ? "font-weight-bold" : ""}`;
  }, []);

  const findTest = useCallback((e: IEvent<IFeatureEventProperties>) => {
    return cache.tests.item(e.properties.testId);
  }, [cache.tests.data]);

  const getUrl = useCallback((e: IEvent<IFeatureEventProperties>) => {
    const test = cache.tests.item(e.properties.testId);
    const url = cache.urls.item(test?.url_id);
    return url?.url;
  }, [cache.urls.data]);

  const getIcon = useCallback((e: IEvent<IFeatureEventProperties>) => {
    switch (e.type) {
      case FeatureEventType.Breakdown:
        return faTimesCircle;

      case FeatureEventType.Recovery:
        return faCheckCircle;

      default:
        return faInfoCircle;
    }
  }, []);

  return (
    <div className="card box box-v box-fill">
      {unreadEvents.length > 0 ? (
        <ul className="list-group list-group-flush box box-v box-fill scroll">
          {unreadEvents.map(e => {
            const
              url = getUrl(e),
              title = getTitle(e);

            return (
              <li
                key={e.id}
                className={`list-group-item clickable box box-h box-gap box-middle ${getCss(e)}`}
                style={{ minWidth: "300px" }}
                onClick={async () => {
                  if (!e._read) {
                    e._read = true;
                    await updateEntities([e]);
                    cache.unreadEvents.reload();
                  }
                }}
              >
                <FontAwesomeIcon
                  icon={getIcon(e)}
                  className={`text-${getTypeCss(e.type)}`}
                  size="lg"
                />

                <div className="box box-v">
                  <div>{title}</div>
                  {url ? (
                    <small>
                      <a href={url}>{url}</a>
                    </small>
                  ) : (
                    EMPTY_STRING
                  )}

                  <small
                    className="text-secondary text-nowrap"
                    title={dateFormatter.format(e.created_on)}
                  >
                    {dateFormatter.elapse(e.created_on, new Date())}
                  </small>
                </div>
              </li>
            );
          })}
        </ul>
      ) : (
        <NoData />
      )}
    </div>
  );
};

export default withEntityEdit(EventList);
