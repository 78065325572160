import React, { FunctionComponent } from "react";
import { Button } from "reactstrap";

export type TBatchOperation<T> = {
  name: string;
  onSubmitFn: (e: T[]) => void;
};

type TBatchOperationsProps<T> = {
  batchOperations?: {
    [key: string]: TBatchOperation<T>;
  };
  rows: T[];
  onSubmitFn?: () => void;
};

export const BatchOperations: FunctionComponent<TBatchOperationsProps<any>> = ({
  batchOperations,
  rows,
  onSubmitFn,
}) => {
  return (
    <div className="box box-h box-middle box-gap self-end">
      <div className="text-nowrap">
        <strong>{rows.length}</strong> wybranych wierszy
      </div>

      {Object.keys(batchOperations).map((e, i) => (
        <Button
          key={"batch" + i}
          color="secondary outline"
          size="sm"
          onClick={() => {
            batchOperations[e].onSubmitFn(rows);
            onSubmitFn();
          }}
        >
          {batchOperations[e].name}
        </Button>
      ))}
    </div>
  );
};
