import React, { FunctionComponent } from "react";
import { dateFormatter } from "../../../../utils/DateFormatter";
import { LineChart } from "../../../@UI/Chart";
import ResponseTimeStats from "./ResponseTimeStats";

const ResponseTimeChart: FunctionComponent<{
    values: number[],
    dates: Date[]
}> = ({ values, dates }) => {
    return (
        <div className="box box-h box-gap box-middle box-wrap">
            <div className="box box-v box-fill" style={{ maxHeight: "50vh" }}>
                <LineChart
                    config={{
                        x: {
                            data: dates.map(e => dateFormatter.format(new Date(e)))
                        },
                        y: {
                            data: values,
                            label: "Czas odpowiedzi serwera [s]",
                        },
                    }}
                    options={{ showLegend: false }}
                />
            </div>
            {values.length > 1 && <ResponseTimeStats values={values} />}
        </div>
    )
};

export default ResponseTimeChart;