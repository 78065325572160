import React, { FunctionComponent } from "react";
import { Badge } from "reactstrap";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type TAppBadgeProps = {
  color: string;
  faIcon?: IconProp;
  text?: string;
  css?: string;
};

const AppBadge: FunctionComponent<TAppBadgeProps> = ({ color, faIcon, text, css = "" }) => (
  <Badge color={color} className={css}>
    <div className="box box-h box-center box-middle box-gap-sm text-nowrap p-1">
      {faIcon && <FontAwesomeIcon icon={faIcon} fixedWidth />}
      {text && <div>{text}</div>}
    </div>
  </Badge>
);

export default AppBadge;
