import React, { FunctionComponent, useMemo } from "react";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import gaIcon from "../../assets/icons/google-analytics.webp";
import wpIcon from "../../assets/icons/wordpress.svg";
import drupalIcon from "../../assets/icons/drupal.svg";
import joomlaIcon from "../../assets/icons/joomla.svg";
import prestashopIcon from "../../assets/icons/prestashop.png";
import cogsIcon from "../../assets/icons/cogs.svg";

export type TUrlProperties = {
    httpsSupport?: boolean,
    gaSupport?: boolean,
    cms?: string
}

export const CMS_ICONS: { [key: string]: string } = {
    "wordpress": wpIcon,
    "drupal": drupalIcon,
    "joomla": joomlaIcon,
    "prestashop": prestashopIcon
};

export const getCmsIcon = (cms: string) => {
    if (!cms)
        return cogsIcon;

    for (const key in CMS_ICONS)
        if (cms.toLowerCase().includes(key))
            return CMS_ICONS[key];
};

export const UrlProperties: FunctionComponent<{ props: TUrlProperties }> = ({ props }) => {
    return (
        <div className="box box-h box-gap">
            {props?.httpsSupport && <FontAwesomeIcon icon={faLock} title="Obsługa protokołu HTTPS." className="text-success" />}
            {props?.gaSupport && <img src={gaIcon} width="16px" height="16px" title="Podpięty Google Analytics." />}
            {props?.cms && <img src={getCmsIcon(props.cms)} width="16px" height="16px" title={`Strona oparta o generator ${props.cms}.`} />}
        </div>
    )
}