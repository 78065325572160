import React, { FunctionComponent, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import validate from "validate.js";
import {
  Button,
  Label,
  Input,
  Form,
  FormGroup,
  FormFeedback,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  CardLink,
} from "reactstrap";
import Logo from "./components/@UI/Logo";
import { routes } from "./routes";
import { SessionContext } from "./context/SessionContext";
import { getUserForSession, loginUser, registerUser, requestPasswordReset } from "./actions/user";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Toast } from "./components/@UI/Services";
import { TAuthData } from "./Types";
import AppFormik from "./components/AppFormik";
import { ErrorService } from "./ErrorService";
import { ModalService } from "./components/@UI/Services/ModalService";
import PasswordInput from "./components/@UI/PasswordInput";

type TAuthPageProps = {
  authType: "login" | "register";
  initialValues?: TAuthData;
};

const AuthPage: FunctionComponent<TAuthPageProps> = ({
  authType = "login",
  initialValues = { email: "", password: "", password2: "" },
}) => {
  const session = useContext(SessionContext);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      {session.user != null && <Redirect to={routes.home} />}

      <div className="content box box-v box-fill box-middle box-center">
        <div className="mb-4">
          <Logo />
        </div>
        <div className="auth-form box box-v box-gap p-5">
          <h3 className="text-center mb-4">
            {authType === "login" && "Logowanie"}
            {authType === "register" && "Rejestracja"}
          </h3>

          <AppFormik
            initialValues={initialValues}
            validate={values => {
              const errors: any = {};

              if (!values.email) errors.email = "Adres email jest wymagany.";
              else {
                const res = validate({ from: values.email }, { from: { email: true } });
                if (res !== undefined) errors.email = "Nieprawidłowy adres email.";
              }

              const PASSWORD_MIN_LEN = 6;
              if (!values.password) errors.password = "Hasło jest wymagane.";
              else if (values.password.length < PASSWORD_MIN_LEN)
                errors.password = `Podane hasło jest za krótkie (min. ${PASSWORD_MIN_LEN} znaków).`;

              if (authType === "register")
                if (!values.password2) errors.password2 = "Proszę powtórzyć hasło.";
                else if (values.password !== values.password2)
                  errors.password2 = "Podane hasła nie pasują do siebie.";

              return errors;
            }}
            onSubmit={async (values: TAuthData) => {
              if (authType === "login") {
                await loginUser(values);
                const user = await getUserForSession();
                session.setUser(user);
              }

              if (authType === "register") {
                await registerUser(values);
                Toast.show("Konto założone pomyślnie.");
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setSubmitting,
              isSubmitting,
            }) => (
              <>
                <Form>
                  <FormGroup>
                    <Label for="email">Adres email</Label>
                    <Input
                      type="text"
                      name="email"
                      id="email"
                      placeholder="Adres email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email?.toLowerCase()}
                      invalid={errors.email != null}
                    />
                    <FormFeedback>{errors.email && touched.email && errors.email}</FormFeedback>
                  </FormGroup>

                  <FormGroup>
                    <Label for="password">Hasło</Label>
                    <PasswordInput
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      name={"password"}
                      error={errors.password}
                      value={values.password}
                    />
                    <FormFeedback>
                      {errors.password && touched.password && errors.password}
                    </FormFeedback>
                  </FormGroup>

                  {authType === "register" && (
                    <FormGroup>
                      <Label for="password2">Powtórz hasło</Label>
                      <PasswordInput
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        name={"password2"}
                        error={errors.password2}
                        value={values.password2}
                      />
                      <FormFeedback>
                        {errors.password2 && touched.password2 && errors.password2}
                      </FormFeedback>
                    </FormGroup>
                  )}
                </Form>
                <Button
                  color="primary"
                  onClick={() => {
                    handleSubmit();
                    setSubmitting(false);
                  }}
                  type="submit"
                  disabled={isSubmitting || Object.keys(errors).length > 0}
                >
                  {authType === "login" && "Zaloguj"}
                  {authType === "register" && "Zarejestruj"}
                </Button>
                {authType === "login" && values.email && <CardLink className="clickable" style={{ margin: "0 auto" }} onClick={async () => {
                  if (errors.email)
                    return ErrorService.process(new Error(errors.email.toString()));

                  await requestPasswordReset(values.email);
                  Modal.open(<>
                    <p>Na podany adres email został wysłany link, dzięki któremu będzie można zresetować hasło.<br />
                      Proszę sprawdzić swoją skrzynkę pocztową.</p>
                    <p>Link będzie ważny przez <b>10 minut.</b></p>
                  </>, { title: "Reset hasła" });
                }}>Zresetuj hasło</CardLink>}
              </>
            )}
          </AppFormik>

          <div className="box box-v box-gap box-middle mt-4">
            {authType === "login" && (
              <>
                <div>Nie masz jeszcze swojego konta?</div>
                <Link to={routes.register}>
                  <Button outline color="info">
                    Załóż nowe konto
                  </Button>
                </Link>
              </>
            )}

            {authType === "register" && (
              <Link to={routes.login}>
                <Button outline color="info">
                  Zaloguj się
                </Button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthPage;
