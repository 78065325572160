import { useFetch } from "../../hooks/useFetch";
import { IUrl } from "../../@shared/types";
import React, { FunctionComponent } from "react";
import Loader from "../Loader";

export const UrlOverview: FunctionComponent<{ url: IUrl }> = ({ url }) => {
    const hostInfoFetch = useFetch<{ ip: string, hostname: string }>(`url/hosting?url=${url.url}`);

    return (
        <div className="box box-h box-gap">
            <div className="box box-fill box-v box-gap">
                <h5>Adres hostingu</h5>
                <Loader pending={hostInfoFetch.pending} align="left">
                    {hostInfoFetch.response ?
                        <a href={`http://${hostInfoFetch.response?.hostname ?? hostInfoFetch.response?.ip}`} target="_blank">
                            {hostInfoFetch.response?.hostname} ({hostInfoFetch.response?.ip})
                        </a> : "Brak danych."}
                </Loader>
            </div>
        </div>
    );
}