export class UserPreferences {
    static instance = new UserPreferences();

    data: {
        datatable?: {
            itemsPerPage?: number
        }
    } = {}

    save() {
        localStorage.setItem("user-preferences", JSON.stringify(this));
    }

    load() {
        const objStr = localStorage.getItem("user-preferences");
        if (!objStr)
            return;

        const obj = JSON.parse(objStr);
        for (const key of Object.keys(obj))
            this[key] = obj[key];
    }
}