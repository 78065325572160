import React, { FunctionComponent, useEffect, useRef } from "react";
import { Badge } from "reactstrap";

export type TSelectorItemsProps = {
  onClick: () => void;
  selected: any[];
  displayFn: (e: any) => string;
  keyFn: (e: any) => any;
  disabled?: boolean;
  onRefInit?: (ref: any) => void;
};

const SelectorItems: FunctionComponent<TSelectorItemsProps> = ({
  onClick,
  selected,
  keyFn,
  displayFn,
  disabled,
  onRefInit
}) => {
  const ref = useRef(null);
  useEffect(() => onRefInit(ref.current), [ref]);

  return (
    <div
      className={`selector-items ${disabled ? "disabled" : ""} box box-h box-gap box-wrap`}
      onClick={() => !disabled && onClick()}
      ref={ref}
    >
      {selected.length > 0 && selected.map(e => <Badge key={keyFn(e)}>{displayFn(e)}</Badge>)}
      {selected.length === 0 && <Badge color="light">Kliknij aby wybrać...</Badge>}
    </div>
  );
};

export default SelectorItems;
