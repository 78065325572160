import React, { FunctionComponent, useMemo } from "react";
import * as Icons from "@fortawesome/free-solid-svg-icons";

import DataTable from "../../../components/DataTable/DataTable";
import { EMPTY_STRING } from "../../../Constants";
import { TCounterData } from "../../../components/feature/availability/Cockpit";
import DefaultTemplate from "../../../templates/Default";
import StateCounter from "../../CardCounter";
import AppBadge from "../../../components/Badge";
import { State, TSslLatestState } from "../../../@shared/types";
import { dateFormatter } from "../../../utils/DateFormatter";
import Loader from "../../Loader";
import { usePagedFeed } from "../../../hooks/useFeed";
import { useFetch } from "../../../hooks/useFetch";

const SslCockpit: FunctionComponent = () => {
  const EXPIRE_SOON_DAYS = 10;

  const feed = usePagedFeed<TSslLatestState>("/ssl/states/latest");
  const countersFeed = useFetch<{
    all: number,
    ok: number,
    nok: number,
    expire_soon: number
  }>(`/ssl/states/latest/counters?expirationDays=${EXPIRE_SOON_DAYS}`);

  const stateCounterData: TCounterData[] = useMemo(() => [
    {
      type: "primary",
      counter: countersFeed.response?.all,
      text: "Monitorowanych certyfikatów",
      icon: Icons.faEye,
    },
    {
      type: "success",
      counter: countersFeed.response?.ok,
      text: "Ważnych certyfikatów",
      icon: Icons.faCheck,
    },
    {
      type: "warning",
      counter: countersFeed.response?.expire_soon,
      text: "Wkrótce wygasających",
      icon: Icons.faExclamation,
    },
    {
      type: "danger",
      counter: countersFeed.response?.nok,
      text: "Wygasłych certyfikatów",
      icon: Icons.faTimes,
    },
  ], [countersFeed.response]);

  return (
    <DefaultTemplate title="Certyfikaty SSL" faIcon={Icons.faLock}>
      <Loader pending={countersFeed.pending}>
        <div className="counter-wrapper mob-hidden">
          {stateCounterData.map((data, i) => (
            <StateCounter
              key={i}
              type={data.type}
              value={data.counter}
              text={data.text}
              faIcon={data.icon}
            />
          ))}
        </div>
      </Loader>

      <DataTable
        feed={feed}
        searchBy={"url"}
        rowKeyFn={e => e.id}
        columnConfig={[
          {
            title: "Adres URL",
            type: "text",
            key: "url",
            renderFn: (e: TSslLatestState) => <a href={e.url} title="Zobacz stronę" target="_blank" rel="noopener noreferrer">{e.url}</a>
          },
          {
            key: "state",
            type: "number",
            title: "Aktualny stan",
            renderFn: (e: TSslLatestState) => {
              switch (e.state) {
                case State.UP:
                  return (
                    <div className="box box-h box-gap box-middle">
                      {
                        e.daysRemaining <= EXPIRE_SOON_DAYS
                          ? <AppBadge color="warning" text={"Wygasa wkrótce"} />
                          : <AppBadge color="success" text={"Ważny"} />
                      }
                    </div>
                  );

                case State.DOWN:
                  return <AppBadge color="danger" faIcon={Icons.faTimesCircle} text="Nie działa" />

                default:
                  return <AppBadge color="secondary" faIcon={Icons.faQuestionCircle} text="Nieznany" />;
              }
            },
          },
          {
            key: "days_remaining",
            title: "Ważność certyfikatu",
            renderFn: (e: TSslLatestState) => (
              <>
                {e.state === State.UP && (
                  <>
                    <div className="font-bold">{dateFormatter.format(e.validTo)}</div>
                    <small>Pozostało:&nbsp;<strong>{e.daysRemaining}</strong>&nbsp;dni</small>
                  </>
                )}

                {e.state === State.DOWN &&
                  (e.validTo
                    ? <small>Wygasł dnia <strong>{dateFormatter.format(e.validTo)}</strong></small>
                    : EMPTY_STRING
                  )}

                {e.state == null && EMPTY_STRING}
              </>
            ),
          },
          {
            title: "Ostatnie sprawdzenie",
            renderFn: (e: TSslLatestState) => e.created_on ? dateFormatter.elapse(e.created_on, new Date()) : "Oczekiwanie..."
          },
        ]}
      />
    </DefaultTemplate>
  );
};

export default SslCockpit;
