import React, { FunctionComponent, useState } from "react";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import Logo from "./@UI/Logo";
import { Form, FormGroup, Label, FormFeedback, Button } from "reactstrap";
import { passwordReset } from "../actions/user";
import AppFormik from "./AppFormik";
import PasswordInput from "./@UI/PasswordInput";
import { routes } from "../routes";
import { Modal } from "./@UI/Services";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

export const UserPasswordReset: FunctionComponent<RouteComponentProps> = ({ location }) => {
    const searchParams = new URLSearchParams(location.search);
    const userId = Number(searchParams.get("id"));
    const token = searchParams.get("token");

    const [finished, setFinished] = useState(false);
    const isValid = !Number.isNaN(userId) && userId && token;
    return (
        <>
            {(!isValid || finished) && <Redirect to={routes.home} />}
            <div className="content box box-v box-fill box-middle box-center">
                <div className="mb-4">
                    <Logo />
                </div>
                <div className="auth-form box box-v box-gap p-5">
                    <h3 className="text-center">Reset hasła</h3>

                    <AppFormik
                        initialValues={{ password: "" }}
                        validate={values => {
                            const errors: any = {};

                            const PASSWORD_MIN_LEN = 6;
                            if (!values.password)
                                errors.password = "Hasło jest wymagane.";

                            if (values.password.length < PASSWORD_MIN_LEN)
                                errors.password = `Podane hasło jest za krótkie (min. ${PASSWORD_MIN_LEN} znaków).`;

                            return errors;
                        }}
                        onSubmit={async (values) => {
                            if (!isValid)
                                return;

                            try {
                                await passwordReset(userId, token, values.password);
                                Modal.confirm("Hasło zostało zresetowane",
                                    "Twoje hasło zostało pomyślnie zresetowane. Możesz się teraz zalogować.",
                                    undefined, undefined, faCheckCircle);
                            } catch (ex) {
                                Modal.confirm("Resetowanie hasła nie powiodło się",
                                    "Coś poszło nie tak podczas resetowania hasła. Spróbuj ponownie.",
                                    undefined, undefined, faTimesCircle);
                            } finally {
                                setFinished(true);
                            }
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setSubmitting,
                            isSubmitting,
                        }) => (
                            <>
                                <Form>
                                    <FormGroup>
                                        <Label for="password">Nowe Hasło</Label>
                                        <PasswordInput
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            name={"password"}
                                            error={errors.password}
                                            value={values.password}
                                        />
                                        <FormFeedback>
                                            {errors.password && touched.password && errors.password}
                                        </FormFeedback>
                                    </FormGroup>
                                </Form>
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        handleSubmit();
                                        setSubmitting(false);
                                    }}
                                    type="submit"
                                    disabled={isSubmitting || Object.keys(errors).length > 0}
                                >
                                    Zapisz
                                </Button>
                            </>
                        )}
                    </AppFormik>
                </div>
            </div >
        </>
    )
}

export default withRouter(UserPasswordReset);