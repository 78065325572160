import React, { FunctionComponent, useContext, useState } from "react";
import { DEFAULT_PERIOD } from "../../../../Constants";
import Loader from "../../../Loader";
import { TestTypeContext } from "../../../../context/TestTypeContext";
import { useFeed } from "../../../../hooks/useFeed";
import { IAvailabilityStateProperties, IFeatureState, TAvailabilityBreakdown } from "../../../../@shared/types";
import ResponseTimeChart from "./ResponseTimeChart";
import TimeRangeSelector from "./TimeRangeSelector";
import Summary from "./Summary";
import DataTable from "../../../DataTable/DataTable";
import { dateFormatter } from "../../../../utils/DateFormatter";

const Statistics: FunctionComponent<{
  testId: number;
  url: string,
}> = ({ testId, url }) => {
  const testType = useContext(TestTypeContext);
  const [period, setPeriod] = useState(DEFAULT_PERIOD);

  const statesHistoryFeed = useFeed<IFeatureState<IAvailabilityStateProperties>>(
    `${testType}/states/${testId}?createdAfterDate=${new Date(Date.now() - period).toISOString()}`,
    [period]
  );

  const breakdownsFeed = useFeed<TAvailabilityBreakdown>(
    `${testType}/breakdowns/${testId}?createdAfterDate=${new Date(Date.now() - period).toISOString()}`,
    [period]
  );

  return (
    <div className="box box-v box-fill">
      <div className="box box-v box-gap box-fill">
        <div className="box box-h box-gap box-middle box-justified box-wrap">
          <h4>Statystyki dostępności dla adresu: <a href={url}>{url}</a></h4>
          <TimeRangeSelector onSelect={v => setPeriod(v)} />
        </div>

        <Loader pending={statesHistoryFeed.pending}>
          <h5>Czas odpowiedzi serwera</h5>
          <ResponseTimeChart
            values={statesHistoryFeed.response.map(e => e.properties?.responseTime)}
            dates={statesHistoryFeed.response.map(e => e.created_on)}
          />
        </Loader>

        <Loader pending={breakdownsFeed.pending && statesHistoryFeed.pending}>
          <h5>Podsumowanie okresu</h5>
          <Summary breakdowns={breakdownsFeed.response} statesHistory={statesHistoryFeed.response} />

          {breakdownsFeed.response.length > 0 && (
            <DataTable
              title="Okresy niedostępności"
              rowKeyFn={e => e.id}
              feed={breakdownsFeed}
              columnConfig={[
                {
                  title: "Czas wykrycia",
                  key: "detected_on",
                  renderFn: (e: TAvailabilityBreakdown) => <div>{dateFormatter.format(e.detected_on)}</div>
                },
                {
                  title: "Czas zakończenia",
                  key: "recovered_on",
                  renderFn: (e: TAvailabilityBreakdown) => <div>{dateFormatter.format(e.recovered_on)}</div>
                },
                {
                  title: "Czas trwania",
                  key: "duration_time",
                  renderFn: (e: TAvailabilityBreakdown) => <div>{dateFormatter.duration(e.detected_on, e.recovered_on ?? new Date())}</div>
                },
                {
                  title: "Przyczyna",
                  key: "error_message",
                  renderFn: (e: TAvailabilityBreakdown) => <>
                    {e.http_code != null && <div>Kod HTTP: {e.http_code}</div>}
                    {e.error_message != null && <pre>{e.error_message}</pre>}
                  </>
                }
              ]}
            />
          )}

        </Loader >

      </div>
    </div >
  );
};

export default Statistics;